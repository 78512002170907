import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { Icon, Menu } from 'semantic-ui-react';

import chapter from '../../../public/logo/chapter.svg';
import author from '../../../public/logo/menu-author.svg';
import book from '../../../public/logo/menu-book.svg';
import home from '../../../public/logo/menu-home.svg';
import subject from '../../../public/logo/menu-subject.svg';

interface Props {
  activeItem: string;
}

const AppMenu: FunctionComponent<Props> = (props: Props) => {
  const { activeItem } = props;
  const router = useRouter();
  const handleItemClick = (route: string) => (): void => {
    router.push(route);
  };

  return (
    <Menu
      icon="labeled"
      vertical
      pointing
      secondary
      className="app-layout-menu"
    >
      <Menu.Item
        name="home"
        active={activeItem === 'home'}
        onClick={handleItemClick('/')}
      >
        <img src={home} className="menu-logo" />
        {'Anasayfa'}
      </Menu.Item>

      <Menu.Item
        name="author"
        active={activeItem === 'author'}
        onClick={handleItemClick('/author')}
      >
        <img src={author} className="menu-logo" />
        {'Yazarlar'}
      </Menu.Item>

      <Menu.Item
        name="BookSubject"
        active={activeItem === 'bookSubject'}
        onClick={handleItemClick('/bookSubject')}
      >
        <img src={subject} className="menu-logo" />
        {'Konular'}
      </Menu.Item>
      <Menu.Item
        name="featured"
        active={activeItem === 'book'}
        onClick={handleItemClick('/book')}
      >
        <img src={book} className="menu-logo" />
        {'Kitaplar'}
      </Menu.Item>

      <Menu.Item
        name="BookChapter"
        active={activeItem === 'bookChapter'}
        onClick={handleItemClick('/bookChapter')}
      >
        <img src={chapter} className="menu-logo" />
        {'Kitap Bölümleri'}
      </Menu.Item>

      <Menu.Item
        name="speaker"
        active={activeItem === 'speaker'}
        onClick={handleItemClick('/speaker')}
      >
        <Icon name="microphone" size="huge" />
        {'Konuşmacılar'}
      </Menu.Item>

      <Menu.Item
        name="podcast"
        active={activeItem === 'podcast'}
        onClick={handleItemClick('/podcast')}
      >
        <Icon name="podcast" size="huge" />
        {'Podcastler'}
      </Menu.Item>

      <Menu.Item
        name="program"
        active={activeItem === 'program'}
        onClick={handleItemClick('/program')}
      >
        <Icon name="th" size="huge" />
        {'Programlar'}
      </Menu.Item>

      <Menu.Item
        name="programChapter"
        active={activeItem === 'programChapter'}
        onClick={handleItemClick('/programChapter')}
      >
        <Icon name="ordered list" size="huge" />
        {'Program Bölümleri'}
      </Menu.Item>

      <Menu.Item
        name="promotion"
        active={activeItem === 'promotion'}
        onClick={handleItemClick('/promotion')}
      >
        <Icon name="newspaper" size="huge" />
        {'Tanıtım & Reklam'}
      </Menu.Item>

      <Menu.Item
        name="editorPicks"
        active={activeItem === 'editorPicks'}
        onClick={handleItemClick('/editorPicks')}
      >
        <Icon name="star" size="huge" />
        {'Editörün Seçtikleri'}
      </Menu.Item>
    </Menu>
  );
};

export default AppMenu;
