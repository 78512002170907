import { useRouter } from 'next/router';
import React, { FunctionComponent, useCallback } from 'react';
import { Button, Header, Image } from 'semantic-ui-react';

// eslint-disable-next-line import/order
import logo from '../../../public/logo/blackLogo.svg';
import signOut from '../../auth/sign-out';

interface Props {
  topic: string;
}

const AppHeader: FunctionComponent<Props> = (props) => {
  const router = useRouter();

  const handleSignOut = useCallback(() => {
    signOut(router);
  }, [router]);
  const { topic } = props;
  return (
    <div className="app-header">
      <Header>
        <Image src={logo} alt="Sesli Kitap" className="logo" />
      </Header>
      <div className="app-header-right">{topic}</div>
      <div className="sign-out-button">
        <Button size={'mini'} color="black" onClick={handleSignOut}>
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default AppHeader;
