import { NextRouter } from 'next/dist/client/router';

import { clearCache } from '@audiobook/relay/environment';
import { deleteSession } from '@audiobook/relay/session.utils';

export default function signOut(router: NextRouter): void {
  deleteSession();
  clearCache();
  router.push('/');
}
