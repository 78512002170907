import React, { FunctionComponent } from 'react';
import { Grid } from 'semantic-ui-react';

import AppMenu from '../app-menu/AppMenu.component';

interface Props {
  activeItem: string;
  children: any;
}

const AppLayout: FunctionComponent<Props> = (props: Props) => {
  const { activeItem, children } = props;
  return (
    <div>
      <Grid>
        <Grid.Row>
          <AppMenu activeItem={activeItem} />
          <Grid.Column className="app-layout-wrapper">{children}</Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default AppLayout;
